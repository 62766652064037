import React, { createContext, useState } from 'react';
import {
    IFolder,
    IHasChildrenProps,
    IReportScheduleDefinition,
    IReportDestinationDefinition,
    IDashboardRef, ILookRef, IUserRef
} from '@src/interfaces';
import {IDashboard, ILook} from "@looker/sdk";

const BASE_ROUTE = "/app/schedule";

export interface ScheduleAPIProviderProps extends IHasChildrenProps {
    values?: IScheduleAPIContext,
    currentFolder_id: string
}

export interface IScheduleAPIContext {
    readonly createSchedule: typeof createSchedule,
    readonly getSchedule: typeof getSchedule,
    readonly getSchedules: typeof getSchedules,
    readonly updateSchedule: typeof updateSchedule,
    readonly deleteSchedule: typeof deleteSchedule,
    readonly moveSchedule: typeof moveSchedule, //This doesn't make sense what are we moving
    readonly copySchedule: typeof copySchedule,
    readonly getFieldSuggestions: typeof getFieldSuggestions,
    readonly scheduleList: Array<IReportScheduleDefinition>,
    readonly setScheduleList?: React.Dispatch<React.SetStateAction<IReportScheduleDefinition[]>>
    readonly getReferencedUser: typeof getReferencedUser,
    readonly getReferencedUsers: typeof getReferencedUsers,
    readonly getReferencedDashboard: typeof getReferencedDashboard,
    readonly getReferencedLook: typeof getReferencedLook,
    readonly getReferencedLooks: typeof getReferencedLooks,
    readonly getReferencedDashboards: typeof getReferencedDashboards,
    readonly reassignOwner: typeof reassignOwner,
    currentSchedule?: IReportScheduleDefinition,
    currentFolder_id?: string
}


interface IGetSchedulesParams{
    folder_id?: string,
    dashboard_id?: string,
    look_id?: string
    type?: "DEFAULT"|"BURST"
}


/**
 * @interface IUsersDict
 * Dictionary of known users referenced in the results.
 * key is user_id reported by Looker (looker_user_id)
 * value is the minimal set of attributes of the user for display
 */
export interface IUsersDict {
    [user_id: string]: IUserRef
}


/**
 * @interface ILooksDict
 * Dictionary of known Looks referenced in the results.
 * key is id of the look
 * value is the minimal set of attributes  for display
 */
export interface ILooksDict {
    [id: string]: ILookRef
}


/**
 * @interface IDashboardsDict
 * Dictionary of known dashboards referenced in the results.
 * key is id of the dashboard
 * value is the minimal set of attributes for display
 */
export interface IDashboardsDict {
    [id: string]: IDashboardRef
}


/**
 * Gets a list of schedules
 * @param folder_id optional
 * @param look_id optional
 * @param dashboard_id optional
 * @returns Repo
 */
const getSchedules = async (params: IGetSchedulesParams) => {
    let query = "?";
    if (params.folder_id) {
        query += "folder_id=" + params.folder_id;
    }
    if (params.look_id) {
        query += "look_id=" + params.look_id;
    }
    if (params.dashboard_id) {
        query += "dashboard_id=" + params.dashboard_id;
    }

    if (params.type) {
        query += "&type=" + params.type;
    }

    return fetch(`${BASE_ROUTE}${query}`).then(async (res) => {
        if(res.ok){
            let body = await res.json() as Array<IReportScheduleDefinition>;

            return body;
        }
        throw Error(res.statusText);
    });
};

/**
 * Get reference to a user
 *
 * @param look_id
 */
const getReferencedUser = async (user_id: string) => {

    // todo: replace with a get user (ref) endpoint when route exists
    return fetch(`/app/users?user_id=${user_id}&ref_only=true`).then(async (res) => {
        if(res.ok){
            let body = await res.json() as IUserRef;
            return body;
        }
        throw Error(res.statusText);
    });
};

/**
 * Get details of the users referenced by the schedules
 *
 * @param user_ids
 * @return UsersDict
 */
const getReferencedUsers = async (user_ids: string[]) => {

    let query = '';
    return fetch('/app/users?ref_only=true').then(async (res) => {
        if(res.ok){
            let users = await res.json();

            const userDictionary: IUsersDict = users.users?.reduce((acc, user) => {
                acc[user.user_id] = user;
                return acc;
            }, {});

            return userDictionary;
        }
        throw Error(res.statusText);
    });
};

/**
 * Get details of the look referenced by the schedule
 *
 * @param look_id
 */
const getReferencedLook = async (look_id: string) => {

    return fetch(`/app/looks/${look_id}/info`).then(async (res) => {
        if(res.ok){
            let body = await res.json() as ILook;
            return {
                id: body.id,
                name: body.title
            } as ILookRef;
        }
        throw Error(res.statusText);
    });
};

const getReferencedLooks = async (look_ids: string[]) => {
    /**
     * Fetches a set of looks by their ids, awaiting all of them
     */
    const fetchPromises = look_ids.map(look_id => fetch(`/app/looks/${look_id}/info?refonly=true`) );

    const responses = await Promise.all(fetchPromises);

    const looks : ILook[] = await Promise.all(responses.map(response => response.json()));

    const lookDictionary: ILooksDict = looks?.reduce((acc, look) => {
        if (look.id) {
            acc[look.id] = {
                id: look.id,
                name: look.title
            } as ILookRef;
        }
        return acc;
    }, {} as ILooksDict);

    return lookDictionary;
};


/**
 * Get details of the dashboard referenced by a schedule
 *
 * @param dashboard_id
 */
const getReferencedDashboard = async (dashboard_id: string) => {

    return fetch(`/app/dashboards/${dashboard_id}/info`).then(async (res) => {
        if(res.ok){
            let body = await res.json() as IDashboard;
            return {
                id: body.id,
                name: body.title
            } as IDashboardRef;
        }
        throw Error(res.statusText);
    });
};

const getReferencedDashboards = async (look_ids: string[]) => {
    /**
     * Fetches a set of dashboards by their ids, awaiting all of them
     */
    const fetchPromises = look_ids.map(look_id => fetch(`/app/dashboards/${look_id}/info?refonly=true`) );

    const responses = await Promise.all(fetchPromises);

    const dashboards : IDashboard[] = await Promise.all(responses.map(response => response.json()));

    const dashboardDictionary: IDashboardsDict = dashboards?.reduce((acc, dashboard) => {
        if (dashboard.id) {
            acc[dashboard.id] = {
                id: dashboard.id,
                name: dashboard.title
            } as IDashboardRef;
        }
        return acc;
    }, {} as IDashboardsDict);

    return dashboardDictionary;
};


function removeEmpty(obj) {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([_, v]) => v != null)

    );
}

/**
 *
 * @param schedule
 * @returns
 */
const createSchedule = (schedule: IReportScheduleDefinition) => {
    delete schedule.id;

    schedule.destinations = schedule.destinations?.map(v => {
        return (removeEmpty(v) as unknown) as IReportDestinationDefinition;
    });

    return fetch(`${BASE_ROUTE}`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(removeEmpty(schedule))
    }).then(async (res) => {
        if(res.ok){
            let body = await res.json() as IReportScheduleDefinition;

            console.log(body);

            return body;
        }
        throw Error(res.statusText);
    });
};

/**
 *
 * @param schedule
 * @returns
 */
const getSchedule = (schedule: IReportScheduleDefinition) => {
    return fetch(`${BASE_ROUTE}/${schedule.id}`).then(async (res) => {
        if(res.ok){
            let body = await res.json() as IReportScheduleDefinition;

            console.log(body);

            return body;
        }
        throw Error(res.statusText);
    });
};

/**
 * @param id
 * @param schedule
 * @returns
 */
const updateSchedule = (id: string, schedule: IReportScheduleDefinition) => {
    return fetch(`${BASE_ROUTE}/${id}`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(schedule)
    }).then(async (res) => {
        if (res.ok) {
            let body = await res.json() as IReportScheduleDefinition;

            console.log(body);

            return body;
        }
        try {
            let errorBody = await res.json();
            if (errorBody) {
                console.log('ScheduleAPIProvider Error Response:', errorBody);
            }
        } catch (e) {
            //...
        }
        throw Error(res.statusText);
    });
};

/**
 *
 * @param schedule
 * @returns
 */
const deleteSchedule = (schedule: IReportScheduleDefinition) => {
    return fetch(`${BASE_ROUTE}/${schedule.id}`, {
        method: "delete"
    }).then(async (res) => {
        if (res.ok){
            return true;
        }
        else{
            return false;
        }
    });
};

const reassignOwner = (scheduleId: string, userId: string) => {
    return fetch(`${BASE_ROUTE}/${scheduleId}`, {
        method: "PATCH",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({'new_user_id': userId})
    }).then(async (res) => {
        if (res.ok){
            return await res.json();
        }
        throw Error();
    });
};

const getFieldSuggestions = (modelName: string, viewName: string, fieldName: string) => {
    /**
     * API call to get suggested values for a field for the current user.
     */
    return fetch(`/app/models/${modelName}/views/${viewName}/fields/${fieldName}/suggestions`, {
        method: "get"
    }).then(async (res) => {
        if (res.ok) {
            return res.json();
        } else {
            return [];
        }
    });
};

//TBD
const moveSchedule = () => {}; //This doesn't make sense what are we moving?
const copySchedule = (schedule: IReportScheduleDefinition) => {
    return createSchedule(schedule);
}; //dont have a defined route for this.

let defaultVals: IScheduleAPIContext = {
    createSchedule: createSchedule,
    getSchedule: getSchedule,
    getSchedules: getSchedules,
    updateSchedule: updateSchedule,
    deleteSchedule: deleteSchedule,
    moveSchedule: moveSchedule, //This doesn't make sense what are we moving?
    copySchedule: copySchedule,
    getFieldSuggestions: getFieldSuggestions,
    scheduleList: [],
    getReferencedDashboard: getReferencedDashboard,
    getReferencedLook: getReferencedLook,
    getReferencedUser: getReferencedUser,
    getReferencedUsers: getReferencedUsers,
    getReferencedLooks: getReferencedLooks,
    getReferencedDashboards: getReferencedDashboards,
    reassignOwner: reassignOwner,
};

export const ScheduleAPIContext = createContext<IScheduleAPIContext>(defaultVals);

export default (props: ScheduleAPIProviderProps) => {
    const [schedules, setSchedules] = useState<Array<IReportScheduleDefinition>>([]);

    let value: IScheduleAPIContext = { ...defaultVals, ...props.values, scheduleList: schedules, setScheduleList: setSchedules,
        copySchedule: async (schedule: IReportScheduleDefinition) => {
            return Promise.resolve(defaultVals.copySchedule(schedule)).then(schedule => {
                return Promise.resolve(defaultVals.getSchedules({folder_id: props.currentFolder_id})).then((schedules: Array<IReportScheduleDefinition>) => {
                    setSchedules(schedules);
                    return schedule;
                });
            });
        } ,
        getSchedules: async (params: IGetSchedulesParams) => {
            return defaultVals.getSchedules(params).then(schedules => {
                setSchedules(schedules);
                return schedules;
            });
        },
        deleteSchedule: async (schedule: IReportScheduleDefinition) => {
            return Promise.resolve(defaultVals.deleteSchedule(schedule)).then((deleted: boolean) => {
                return Promise.resolve(defaultVals.getSchedules({folder_id: props.currentFolder_id})).then((schedules: Array<IReportScheduleDefinition>) => {
                    setSchedules(schedules);
                    return deleted;
                });
            });
        },
        getFieldSuggestions: async (modelName: string, viewName: string, fieldName: string)=> {
            return defaultVals.getFieldSuggestions(modelName, viewName, fieldName).then(suggestions => {
                return suggestions;
            });
        },
        getReferencedDashboard: async (dashboard_id: string)=> {
            return defaultVals.getReferencedDashboard(dashboard_id).then(dashboard => {
                return dashboard;
            });
        },
        getReferencedLook: async (look_id: string)=> {
            return defaultVals.getReferencedLook(look_id).then(look => {
                return look;
            });
        },
        getReferencedUsers: async (user_ids: string[])=> {
            return defaultVals.getReferencedUsers(user_ids).then(users => {
                return users;
            });
        },
        reassignOwner: async (scheduleId: string, userId:string) => {
            return Promise.resolve(defaultVals.reassignOwner(scheduleId, userId)).then(id => {
                setSchedules(schedules);
                return id;
            });
        }
    };

    return (
        <ScheduleAPIContext.Provider value={value}>
            {props.children}
        </ScheduleAPIContext.Provider>
    );
};