import { LDFlagSet } from "launchdarkly-js-client-sdk";

/**
 * @private
 * flag set in this file scope to determine whether to format flag selectors from LD to config cat standards.
 * it is modified by setShouldFormatFlag() which is called when SET_USER_FEATURE_FLAGS action is dispatched to the redux store.
 * can remove this when analytics_app_enableconfigcat is deprecated
 */
let _shouldFormatFlagSelector;

/**
 * can remove when analytics_app_enableconfigcat is deprecated
 * @param bool 
 */
export const setShouldFormatFlag = (bool: boolean) => {
  _shouldFormatFlagSelector = bool;
};

// can remove this fn when analytics_app_enableconfigcat is deprecated
const _formatFlag = (flagSelector: string) => {
  // format to config cat syntax
  const formattedFlag = flagSelector.replace(/\./g, "_").replace(/\-/g, "_");
  return formattedFlag;
};

export enum FFlags {
  LaunchDarklyTest = "analytics.app.launchdarklytest",
  DeleteLooks = "analytics.app.CRA-4521-delete-dashboards-looks",
  EnableNewInternalAdmin = "analytics.app.CRA-4560-internal-admin",
  RenameLooksDashboard = "analytics.app.CRA-4643-rename-look-dash",
  EnableAdminUserManagement = "analytics.app.CRA-4607-admin-user-fe",
  EnableMenuModelSorting = "analytics.app.CRA-4875-sort-models-fe",
  EnableCustomerSearch = "app.analytics.CRA-5157-customer-search-fe",
  EnableInternalEditDesc = "app.analytics.CRA-5154-internal-edit-desc",
  ShowDescStatusInternal = "app.analytics.CRA-5309.desc-show-status",
  ShowCopyInfo = "analytics.app.CRA-5270-show-copy-info",
  EnableReplaceDashboard = "analytics.app.CRA-8350-replace-dashboard",
  AddScheduleToActionBar = "analytics.app.CRA-5334-add-action-actbar",
  EnableDistributionType = "analytics.app.CRA-5911-enable-dist-select",
  EnableDeleteSchedule = "analytics.app.CRA-5830-delete-schedule",
  EnableSaveBurst = "analytics.app.CRA-6025-save-burst-schedule",
  EnableOverrideFilters = "analytics.app.CRA-5341-add-override-to-filters",
  InitializePendo = "analytics.app.CRA-6217-send-pendo-visitor-id",
  SendPendoAccountID = "analytics.app.CRA-6634-send-pendo-account-id",
  EnableAdminMenu = "analytics.app.CRA-6671-enable-admin-menu",
  UseNewHelpSiteLink = "analytics.app.CRA-7440-use-new-help-site-link",
  EnablePendoHelpMenu = "analytics.app.CRA-7440-help-link-to-emburse-assistant",
  EnablePendoUpgradeToProModal = "analytics.app.CRA-7212-pendo-upgrade-to-pro-modal",
  LicensesCardsRedesign = "analytics.app.CRA-7727-licenses-cards-redesign",
  EditUserModalRefactor = "analytics.app.CRA-7783-edit-user-modal-refactor",
  UserManagementInlineActions = "analytics.app.CRA-7794-user-management-inline-actions",
  LookerV4API = "analytics.app.CRA-7315-looker-api-v4.0",
  AdminViewSchedules = "analytics.app.CRA-8341-admin-view-schedules",
  BurstEnabledForInternalUsersOnly = "analytics.app.CRA-8493-burst-internal-users-only",
  SelectDistributionLookInAnyFolder = "analytics.app.CRA-8548-select-distribution-look-in-any-folder",
  FreeCreatorCannotCreateNewDashboard = "analytics.app.CRA-8777-free-creator-cannot-create-new-dashboard",
  FreeCreatorCannotCopyExistingDashboard = "analytics.app.CRA-8778-free-creator-cannot-copy-existing-dashboard",
  FreeCreatorCannotMoveExistingDashboard = "analytics.app.CRA-8786-free-creator-cannot-move-existing-dashboard",
  EnableConfigCat = "analytics_app_enableconfigcat",
  FormatHours24 = "analytics_app_CRA_8503_format_hours_24",
  FreeCreatorCannotDeleteDashboard = "analytics.app.cra_8835_free_creator_cannot_delete_dashboard",
  EmptyScheduleFormNameInput = "analytics_app_cra_9082_empty_schedule_form_name_field",
  FreeCreatorHasCreateScheduleButton = "analytics_app_cra_8766_free_creator_has_create_schedule_button",
  DisableSuggestionsForDynamicFilters = "analytics_app_cra_9149_disable_suggestions_for_dynamic_filters",
  PreventMultipleBurstCreation = "analytics_app_CRA_9193_prevent_multiple_burst_creation",
  DefaultAdvancedOpions = "analytics_app_CRA_9193_default_advanced_options",
  SortFolderContentsInDistListModal = "analytics_app_CRA_9212_sort_folder_contents_in_distribution_modal",
  PreserveMinuteValueForHourlySchedule = "analytics_app_cra_9220_preserve_minute_value_for_hourly_schedule",
  UseNewScheduleAdminComponent = "analytics.app.CRA-8950-new-schedule-admin-component",
  RemapPNGFormatOnEdit = "analytics.app.CRA-9290-fix-png-format-on-schedule-edit",
  WarnDistributionFieldsNotMappedToFilters = "analytics_app_cra_9226_warn_distribution_fields_not_mapped_to_filter",
  EnableBurstScheduleAdminComponent = "analytics.app.CRA-9295-new-burst-schedule-admin-component",
  SaveEditSftpSchedules = "analytics_app_cra_9281_save_edit_sftp_schedules",
  HasDistributionListSelected = "analytics_app_cra_9322_has_distribution_list_selected",
  EnableOrientationInScheduleForm = "analytics_app_cra_9349_enable_orientation_in_schedule_form",
  InlineMoreActions = "analytics_app_cra_9333_inline_more_actions",
  ShowArchiveInEmbarkHeaderMenu = "analytics_app_cra_9507_show_archive_folder_in_menu",
  ScheduleAdminShowOwnerNameDashLookName = "analytics_app_cra_9530_schedule_admin_show_owner_dash_look_name",
  ScheduleAdminDeleteSchedule = "analytics_app_cra_9532_schedule_admin_delete_schedule",
  ScheduleAdminGridMoreActions = "analytics_app_cra_9532_schedule_admin_grid_more_actions_icon",
  ViewScheduleOnScheduleAdminNameClick = "analytics_app_cra_9727_view_schedule_on_schedule_admin_name_click",
  ScheduleAdminReassignOwner = "analytics_app_cra_9533_reassign_owner",
}


export const getFlag = (flagSelector: string) => {
  // can remove the flag check when analytics_app_enableconfigcat is deprecated
  if (_shouldFormatFlagSelector) {
    return _formatFlag(flagSelector);
  }
  return flagSelector;
};


export interface IFFlags extends LDFlagSet {
  [FFlags.LaunchDarklyTest]?: boolean;
}
