import { ILook, IState } from "@src/interfaces";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IEmburseFolderStructure } from "../../interfaces/IEmburseFolderStructure";
import { useSelector } from "react-redux";
import { IFolder } from "../../interfaces/IFolder";
import { v4 } from "uuid";
import "./ModalFolderSelect.scss";
import { FolderIcon, LoadingSpinner } from "..";
import { Icon } from "@emburse/embark-core";
import { flagsSelector } from "../../store/selector";
import { FFlags, getFlag } from "@src/enums";
import { IFolderState } from "../../store/reducers/foldersReducer";
import { LookIcon } from "../Icons/Icons";
export interface ModalFolderSelectProps {
  currentFolder: (IEmburseFolderStructure | undefined)[];
  setCurrentFolder: any;
  selectedFolder: IEmburseFolderStructure | undefined;
  setSelectedFolder: any;
  selectedLook?: ILook;
  setSelectedLook?: React.Dispatch<React.SetStateAction<ILook | undefined>>;
  includeLooks?: boolean;
  isLoading?: boolean;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default (props: ModalFolderSelectProps) => {
  const flags = useSelector(flagsSelector);
  const [looks, setLooks] = useState<ILook[]>([]);

  const { folderContent, folderStructure }: IFolderState = useSelector(
    (state: IState) => state.folders
  );

  useEffect(() => {
    if (flags?.[getFlag(FFlags.SelectDistributionLookInAnyFolder)]){
      props.setCurrentFolder([folderStructure]);
    } else {
      props.setCurrentFolder(folderStructure?.children);
    }
  }, []);

  useEffect(() => {
    if (flags?.[getFlag(FFlags.EnableDistributionType)]) {
      if (props.includeLooks) {
        props.setIsLoading?.(true);
        if (flags?.[getFlag(FFlags.SelectDistributionLookInAnyFolder)]) {
          fetch(`/app/folders/${props.currentFolder[0]?.data?.id}/content`)
            .then(async (res) => {
              let body: { looks; folders; dashboards } = await res.json();

              if (flags?.[getFlag(FFlags.SortFolderContentsInDistListModal)]){
                setLooks(body.looks.sort((l1, l2) =>l1.title.localeCompare(l2.title)));
              } else {
                setLooks(body.looks);
              }
              props.setIsLoading?.(false);
            })
            .catch((err) => {
              props.setIsLoading?.(false);
            });
        } else {
            if (props.currentFolder[0]?.parent) {
              fetch(`/app/folders/${props.currentFolder[0]?.parent}/content`) //parent because current is a list of children
                .then(async (res) => {
                  let body: { looks; folders; dashboards } = await res.json();

                  console.log(props, body);
                  setLooks(body.looks);
                  props.setIsLoading?.(false);
                })
                .catch((err) => {
                  props.setIsLoading?.(false);
                });
            }
          }

      } else {
        props.setIsLoading?.(false);
      }
    } else {
      props.setIsLoading?.(false);
    }
  }, [props.currentFolder]);

  const getCurrentFolderChildren = () => {
    if (flags?.[getFlag(FFlags.SelectDistributionLookInAnyFolder)]) {
      if (flags?.[FFlags.SortFolderContentsInDistListModal]) {
        const subFolders = props.currentFolder[0]?.children || [];
        // list folders that have create permission
        const permittedFolders = subFolders.filter(
          (item: IEmburseFolderStructure | undefined) =>
            item?.type === "folder" &&
            (item?.data as IFolder).permissions?.create
        );
        // sort by name
        return permittedFolders.sort((f1,f2) => (f1.data as IFolder).name.localeCompare((f2.data as IFolder).name));
      } else {
        return props.currentFolder[0]?.children || [];
      }
    } else {
      const subFolders = props.currentFolder || [];
      return subFolders.filter(
        (item: IEmburseFolderStructure | undefined) =>
          item?.type === "folder" &&
          (item?.data as IFolder).permissions?.create
      );
    }
  };
  return (
    <>
      <ul className={"da-modal-folder-select"}>
        {getCurrentFolderChildren()
          .map((item: IEmburseFolderStructure | undefined) => {
            let data: IFolder = item?.data as IFolder;
            if (item?.type === "folder" && data?.permissions?.create) {
              let folder: IFolder = item.data as IFolder;
              let childFolderLength = item.children.filter(
                (el) => el.type === "folder"
              ).length;
              return (
                <li
                  className={`da-modal-folder-select-item ${
                    props.selectedFolder?.key === item?.key
                      ? "da-modal-folder-select-item--selected"
                      : ""
                  }`}
                  key={v4()}
                  onClick={() => props.setSelectedFolder(item)}
                  onDoubleClick={() => {
                    if (flags?.[getFlag(FFlags.SelectDistributionLookInAnyFolder)]) {
                      props.setCurrentFolder([item]);
                    } else {
                      childFolderLength > 0
                        ? props.setCurrentFolder(item?.children)
                        : null;
                    }
                  }}
                >
                  <span className="da-select-item-folder-icon">
                    <Icon iconUrl={<FolderIcon />} />
                  </span>
                  <span>{folder.name}</span>
                  {childFolderLength > 0 && (
                    <span className="da-select-item-arrow-icon">
                      <Icon iconUrl={<ArrowForwardIosIcon />} />
                    </span>
                  )}
                </li>
              );
            }
            return null;
          })}
        {flags?.[getFlag(FFlags.EnableDistributionType)] &&
          !props.isLoading &&
          props.includeLooks &&
          looks.map((l) => (
            <li
              key={v4()}
              className={`da-modal-folder-select-item ${
                props.selectedLook?.id === l?.id
                  ? "da-modal-folder-select-item--selected"
                  : ""
              }`}
              onClick={() => props.setSelectedLook?.(l)}
            >
              <span
                className="da-select-item-folder-icon--green"
                style={{ color: "#00A19D" }}
              >
                <Icon
                  className="da-icon-look"
                  style={{ color: "#00A19D" }}
                  iconUrl={<LookIcon />}
                />
              </span>
              <span>{l.title}</span>
            </li>
          ))}
      </ul>
    </>
  );
};
