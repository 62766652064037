import { useSelector } from "react-redux";
import {
  Button,
  ButtonContainer,
  Grid,
  Icon,
  Modal,
  ModalFooter,
  ModalHeader,
} from "@emburse/embark-core";
import React, { useEffect, useState } from "react";
import { ModalFolderSelect } from "@src/components";
import { IEmburseFolderStructure, ILook, IState } from "@interfaces";
import { ArrowBackIcon } from "@emburse/embark-icons";
import { findNode } from "@src/utility";
import { flagsSelector } from "../../store/selector";
import { FFlags, getFlag } from "@src/enums";
import { fetchFolderContent } from "../../store/actions/foldersActions";
import { modalLoading, modalLoaded } from "../../store/actions/modalActions";

export interface FileBrowserModalProps {
  title: string;
  includeLooks?: boolean;
  includeDashboards?: boolean;
  okButtonText: string;
  cancelButtonText: string;
  onCancel?: () => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLook?: ILook;
  setSelectedLook?: React.Dispatch<React.SetStateAction<ILook | undefined>>;
}

export default (props: FileBrowserModalProps) => {
  const flags = useSelector(flagsSelector);
  const { folderStructure } = useSelector((state: IState) => state.folders);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [oldSelectedLook, setOldSelectedLook] = useState<ILook | undefined>(
    props.selectedLook
  );

  const [currentFolder, setCurrentFolder] = useState<
    (IEmburseFolderStructure | undefined)[]
  >([folderStructure]);

  const [selectedFolder, setSelectedFolder] = useState<IEmburseFolderStructure>(
    folderStructure as IEmburseFolderStructure
  );

  const closeModal = () => {
    props.setIsOpen(false);
  };

  const cancelModal = () => {
    props.setSelectedLook?.(oldSelectedLook);
    props.onCancel?.();
    props.setIsOpen(false);
  };

  const confirmModal = () => {
    props.setIsOpen(false);
  };

  const modalHeader = (
    <ModalHeader
      title={props.title}
      showClose={true}
      onCloseClicked={closeModal}
    ></ModalHeader>
  );

  const modalFooter = (
    <ModalFooter>
      <ButtonContainer alignButtons={"right"}>
        <Button onClick={cancelModal} variant="outlined">
          {props.cancelButtonText}
        </Button>
        <Button variant="contained" onClick={confirmModal}>
          {props.okButtonText}
        </Button>
      </ButtonContainer>
    </ModalFooter>
  );
  return (
    <Modal
      open={props.isOpen}
      isLoading={isLoading}
      modalHeader={
        <Grid container direction="row">
          {(flags?.[getFlag(FFlags.SelectDistributionLookInAnyFolder)] ? currentFolder[0]?.parent : currentFolder[0]?.parent != "all") && (
            <Grid
              item
              style={{
                width: "75px",
                paddingLeft: "28px",
                display: "flex",
                alignItems: "center",
              }}
              direction="column"
              justifyContent="center"
            >
              <div
                onClick={() => {
                  let node = findNode(
                    folderStructure as IEmburseFolderStructure,
                    selectedFolder?.parent as string | number
                  );

                  if (node) {
                    setSelectedFolder(node);
                    if (flags?.[getFlag(FFlags.SelectDistributionLookInAnyFolder)]) {
                      setCurrentFolder([node]);
                    } else {
                      setCurrentFolder(node.children);
                    }
                  } else {
                    console.log(
                      "Could not find parent node in folder structure."
                    );
                  }
                }}
              >
                <Icon iconUrl={ArrowBackIcon} />
              </div>
            </Grid>
          )}
          <Grid item style={{ flexGrow: 1 }}>
            {modalHeader}
          </Grid>
        </Grid>
      }
      modalFooter={modalFooter}
      onClose={closeModal}
    >
      <div className="da-modal-form" style={{overflow:"scroll"}}>
        <ModalFolderSelect
          currentFolder={currentFolder}
          setCurrentFolder={setCurrentFolder}
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          includeLooks
          setSelectedLook={props.setSelectedLook}
          selectedLook={props.selectedLook}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </div>
    </Modal>
  );
};
function dispatch() {
  throw new Error("Function not implemented.");
}
