import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import "./FolderView.scss";
import {
  fetchFolderContent,
  selectFolderItem,
} from "../../store/actions/foldersActions";
import {
  addAlert,
  openDashboard,
  openFolder,
  openLook,
} from "../../store/actions/appActions";
import EmptyFolder from "../EmptyFolder/EmptyFolder";
import FolderMoreActions from "../FolderMoreActions/FolderMoreActions";
import { AnalyticsRole } from "../../enums/AnalyticsRole";
import { AuthContext } from "../AuthProvider/AuthProvider";
import { IUseAuthInterface } from "../../hooks/useAuth";
import {
  DashboardIcon,
  LookIcon,
  FolderIcon,
  CalendarIcon,
} from "../Icons/Icons";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import CheckAuthorization from "../CheckAuthorization/CheckAuthorization";
import {
  IState,
  IDataTableColumn,
  IUseDrawer,
  IDashboard,
  ILook,
  IFolder,
  IAlert,
} from "@src/interfaces";
import { showScheduleModal } from "@src/store";
import { createUseStyles } from "react-jss";
import {
  Table,
  TableBody,
  TableCell as XTableCell,
  TableHead,
  TableRow,
  Typography as XTypography,
} from "@emburse/embark-core";
import { Severity, Styles } from "@src/enums";
import { flagsSelector } from "../../store/selector";
import {
  ScheduleAPIContext,
  IScheduleAPIContext,
} from "../ScheduleAPIProvider/ScheduleAPIProvider";
import { IScheduledPlan } from "@looker/sdk/lib/4.0/models";
import { FFlags, getFlag } from "@src/enums";
const TableCell = props => <XTableCell size='small' {...props}/>;
const Typography = props => <XTypography color='textSecondary' variant='inherit' {...props}/>;
const useStyles = createUseStyles({
  titleDescription: {
    maxWidth: "850px",
  },
  title: {},
  description: {
    display: "block",
    margin: "3px 0",
    fontSize: "13px",
    color: "rgb(85,85,85)",
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: "700px",
  },
  nameIcon: {
    height: "24px",
    display: "flex",
    alignItems: "center",
    paddingRight: Styles.Gutters.Padding(2),
  },
  folderIcon: {
    color: Styles.Colors,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",

    "> svg": {
      height: "15px",
    },
  },
  actionItem: {
    transform: "scale(.625)",
    cursor: "pointer",
  },
});

const NameField = (props: {
  item: IDashboard | ILook | IFolder;
  type: "folder" | "dashboard" | "look";
}) => {
  const styles = useStyles();
  const maxDescriptionDisplay = 256;
  return (
    <>
      <div className={styles.nameContainer}>
        <div className={styles.nameIcon}>
          {props.type == "folder" && (
            <Typography color="textSecondary">
              <FolderIcon />
            </Typography>
          )}

          {props.type == "dashboard" && <DashboardIcon />}

          {props.type == "look" && <LookIcon />}
        </div>
        <div>
          {props.item["title"] ? props.item["title"] : props.item["name"]}
          {props.item["description"] && (
            <span className={styles.description}>
              {props.item["description"]?.slice(0, maxDescriptionDisplay)}
              {props.item["description"]?.length > maxDescriptionDisplay
                ? "..."
                : ""}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

const ActionField = (props: {
  dashboard?: IDashboard;
  look?: ILook;
  folderId: any;
}) => {
  const styles = useStyles();
  const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);
  const dispatch = useDispatch();

  const scheduleClickHander = () => {
    dispatch(showScheduleModal());
  };

  return (
    <>
      <div className={styles.actions}>
        <div onClick={() => scheduleClickHander()}>
          {scheduleAPI.scheduleList.filter((s) => {
            if (props.dashboard) {
              return s.dashboard_id == props.dashboard.id;
            }
            if (props.look) {
              return s.look_id == props.look.id;
            }
            return false;
          }).length > 0 && <CalendarIcon className={styles.actionItem} />}
        </div>
      </div>
    </>
  );
};

export default () => {
  //Hooks
  const dispatch = useDispatch();
  const { folderId } = useParams();
  const styles = useStyles();
  // const [clickedRow, setClickedRow] = useState<{row: GridRowParams | null, clickedOnce: boolean}>({row: null, clickedOnce: false})
  const [schedules, setSchedules] = useState<IScheduledPlan[]>([]);
  const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);

  // Get state items
  const { allFolders, folderContent, folderStructure } = useSelector(
    (state: IState) => state.folders
  );
  const { userData } = useSelector((state: IState) => state.user);
  const flags = useSelector(flagsSelector);
  const authContext: IUseAuthInterface = useContext(AuthContext);
  const tableHeaderLabels = ['Name', 'Model', 'Updated'];
  
  flags?.[getFlag(FFlags.InlineMoreActions)] && [AnalyticsRole.ProCreator, AnalyticsRole.FreeCreator].includes(authContext.user?.analytics_role as AnalyticsRole) && tableHeaderLabels.push('Scheduled');
  const { folders, dashboards, looks } = folderContent;
  const folderName =
    folderContent.id &&
    allFolders[folderContent.id] &&
    allFolders[folderContent.id].name;
  const maxDescriptionDisplay = 256;

  useEffect(() => {
    if (userData.authorized && folderId !== folderContent.id)
      dispatch(fetchFolderContent(folderId, folderStructure));
  }, [dispatch, folderId, folderContent, userData.authorized]);

  useEffect(() => {
    if (folderId != "all") {
      scheduleAPI.getSchedules({ folder_id: folderId }).then((res) => {
        setSchedules(res);
      }).catch(e => {
        let alert: IAlert = {
          timestamp: new Date().getTime(),
          severity: Severity.ERROR,
          message: "An error occurred when fetching the schedules for this folder."
        };
        dispatch(addAlert(alert));
      });
    }
  }, [folderId, flags]);

  const isLoading = folderId !== folderContent.id;
  const isEmpty = folders?.length + dashboards?.length + looks?.length === 0;

  if (isLoading && userData.authorized) return <LoadingSpinner />;

  if (isEmpty && userData.authorized) return <EmptyFolder />;
  const itemClass = (content) =>
    content.contentMetadataId === folderContent.selectedItemId
      ? "da-selected-item"
      : "";
  const folderType = (content) =>
      content.isPersonal
      ? "folder-personal"
      : "folder-shared";



  const getLookOrDashboardJSX = (item, itemType) => {
    const options = {
      dashboard: {
        onDoubleClickHandler: openDashboard
      },
      look: {
        onDoubleClickHandler: openLook
      }
    };

    return (
      <tr
        key={item.contentMetadataId}
        className={itemClass(item)}
        onDoubleClick={() => dispatch(options[itemType].onDoubleClickHandler(item.id))}
        onClick={() => dispatch(selectFolderItem(item))}
        data-tag={item.title}
      >
        <TableCell>
          <NameField item={item} type={itemType} />
        </TableCell>

        <TableCell>
          <Typography>{item.model ? item.model.label : ""}</Typography>
        </TableCell>

        <TableCell>
          <Typography>
            {toDateString(itemType === 'dashboard' ? item.createdAt : item.updatedAt)}
          </Typography>
        </TableCell>
        <TableCell>
          <ActionField
            folderId={folderId}
            dashboard={itemType === 'dashboard' ? item : ''}
            look={itemType === 'look' ? item : ''}
          />
        </TableCell>

        {/* only creator roles will have permission to modify */}
        {flags?.[getFlag(FFlags.InlineMoreActions)] && [AnalyticsRole.ProCreator, AnalyticsRole.FreeCreator].includes(authContext.user?.analytics_role as AnalyticsRole) && <TableCell>
          <FolderMoreActions />
        </TableCell>}
      </tr>
    );
  };

 const getFolderActions = () => {
  if ([AnalyticsRole.ProCreator, AnalyticsRole.FreeCreator].includes(authContext.user?.analytics_role as AnalyticsRole)) {
    return <FolderMoreActions />;
  }
  return;
 };

  return (
    <CheckAuthorization>
      <div className="da-folder-view">
        <h2>{folderName}</h2>

        {(folders || dashboards || looks) && (
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaderLabels.map(label => <TableCell key={label}>{label}</TableCell>)}
                  <TableCell>
                    <div></div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {folders
                  ?.sort((f1, f2) => f1.name.localeCompare(f2.name))
                  .map((folder) => (
                    <tr
                      key={folder.contentMetadataId}
                      className={itemClass(folder)}
                      onDoubleClick={() => dispatch(openFolder(folder.id))}
                      onClick={() => dispatch(selectFolderItem(folder))}
                      data-tag={folderType(folder)}
                    >
                      <TableCell>
                        <NameField item={folder} type="folder" />
                      </TableCell>
                      <TableCell>{""}</TableCell>
                      <TableCell>{""}</TableCell>
                      <TableCell>{""}</TableCell>
                      {flags?.[getFlag(FFlags.InlineMoreActions)] && [AnalyticsRole.ProCreator, AnalyticsRole.FreeCreator].includes(authContext.user?.analytics_role as AnalyticsRole) &&
                      <TableCell>{folder.permissions.update ? getFolderActions(): ""}</TableCell>}
                    </tr>
                  ))}
                {dashboards
                  ?.sort((d1, d2) => d1.title.localeCompare(d2.title))
                  .map((dashboard) => getLookOrDashboardJSX(dashboard, 'dashboard'))
                }
                {looks
                  ?.sort((l1, l2) => l1.title.localeCompare(l2.title))
                  .map((look) => getLookOrDashboardJSX(look, 'look'))
                }
              </TableBody>
            </Table>
            )}
      </div>
    </CheckAuthorization>
  );
};

const toDateString = (timestamp) => new Date(timestamp * 1000).toDateString();
